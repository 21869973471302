<style scoped>
	.template {width: 100%;height: 100%;}
	.max_container {width: 100%;height: 100%;display: flex;}
	.left_nav_box{min-width: 10rem;}
	.el-menu{height: 100%;background-color: #67CD80;}
	.el-submenu .el-menu-item{min-width: 100px;}
	.main_box{flex: 1;}
	.el-menu-item.is-active{background-color: #FFF;font-weight: bold;}
	
</style>
<style>
	ul.el-menu.el-menu--inline{background-color: #67CD80;}
</style>
<template>
	<div class="template">
		<div class="max_container">
			<div class="left_nav_box">
				<el-menu default-active="2" class="el-menu-vertical-demo mc" @open="handleOpen" @close="handleClose" 
				 text-color="#333" active-text-color="#000000" @select="handleSelect" router :default-active="$route.path">
					<div v-for="item in menusList">
						<el-submenu :index="item.path+''" v-if="item.children.length!=0">
							<template slot="title">
								<span>{{item.name}}</span>
							</template>
							<el-menu-item :route="{ path: item1.path, query: { i: item.parentId } }" :index="item1.path+''" v-for="item1 in item.children">
								<span slot="title">{{item1.name}}</span>
							</el-menu-item>
						</el-submenu>
						<el-menu-item :route="{ path: item.path, query: { i: item.parentId } }" :index="item.path+''" v-else>
							<span slot="title">{{item.name}}</span>
						</el-menu-item>
					</div>
				</el-menu>
			</div>
			<div class="left_data_box">

			</div>
			<div class="main_box">
				
				<router-view name="conContainer"></router-view>
			</div>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		data() {
			return {
				navId:0,
				ro:true,
				menusList:[]
			}
		},
		created: function() {
			_this = this;
			_this.navId = _this.$route.query.i;
			_this.getNavData();
		},
		mounted: function() {
			
		},
		methods: {
			//__** 获取菜单
			getNavData(){
				var params = {
					id:_this.navId
				};
				_this._getApi('/wy/account/getRuleListByAdmin', params)
					.then((res) => {
						if (res.code == 1) {
							var baseList = res.data;
							for (var i = 0; i < baseList.length; i++) {
								if(baseList[i].id==_this.navId){
									_this.menusList = baseList[i].children;
									break;
								}
							}
							_this.$router.push({
								path: _this.menusList[0].path,
								query: {i:_this.menusList[0].parentId}
							})
						} else {
							
						}
					})
					.catch((err) => {})
			},
			handleOpen(key, keyPath) {
				console.log(key, keyPath);
			},
			handleClose(key, keyPath) {
				console.log(key, keyPath);
			},
			handleSelect(key, keyPath){
				_this.$router.push({
					path: key,
					query: {i:_this.navId}
				})
			}
		}
	}
</script>
